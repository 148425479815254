import React from 'react';
import './Searchbar.css';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const Searchbar = (props) => {
  return (
    <div className={'Searchbar'}>
      <Autocomplete
        size={'small'}
        onChange={(e, newValue) => props.onChangeHandler(newValue)}
        inputValue={props.value || ''}
        onInputChange={(e, newValue) => props.onInputChangeHandler(newValue)}
        loading={props.loading}
        disableClearable
        options={props.options}
        groupBy={(option) => option.group.charAt(0).toUpperCase() + option.group.slice(1)}
        style={{width: '500px'}}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        PaperComponent={({ children }) => (
          <Paper style={{ backgroundColor: '#F4F2ED' }}>{children}</Paper>
        )}
        renderInput={(params) =>
          <TextField
            size={'small'}
            {...params}
            value={params.name}
            label='Search'
          />
        }
      />
    </div>
  );
}

export default Searchbar;