import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ContentAccordion = (props) => {
  const items = props.items || [];
  const header = props.header;
  const commonStyle = {backgroundColor: 'transparent', boxShadow: 'none', border: '1px solid rgba(30,6,42,0.2)'};

  return (
    <div>
      <Accordion
        style={commonStyle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
        >
          {`${header || 'Category'} (${items.length})`}
        </AccordionSummary>
        <AccordionDetails>
          {items.map((item) => {
            return (
              <Card
                key={item.id}
                style={{...commonStyle, marginBottom: '5px'}}
              >
                <CardActionArea
                  disableRipple
                  onClick={() => props.onClickHandler(item)}
                >
                  <CardContent>
                    {props.displayFunc(item)}
                  </CardContent>
                </CardActionArea>
              </Card>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ContentAccordion;