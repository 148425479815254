import React from 'react';
import ContentAccordion from './ContentAccordion/ContentAccordion';
import './Content.css';

import Chip from '@mui/material/Chip';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const colorMap = {
  startup: '#325229',
  investor: '#094750',
  firm: '#C0684B',
  industry: '#5A5277',
  deal: '#67453A',
}

const chipMap = {
  startup: <Chip label='Startup' style={{backgroundColor: colorMap.startup, color: 'white'}}/>,
  industry: <Chip label='Industry' style={{backgroundColor: colorMap.industry, color: 'white'}}/>,
  firm: <Chip label='Investment Firm' style={{backgroundColor: colorMap.firm, color: 'white'}}/>,
  investor: <Chip label='Investor' style={{backgroundColor: colorMap.investor, color: 'white'}}/>,
  deal: <Chip label='Deal' style={{backgroundColor: colorMap.deal, color: 'white'}}/>
}

const makeHeaderButton = (type, url) => {
  if (!url.startsWith('https://')) {
    url = 'https://' + url;
  }
  switch (type) {
    case 'linkedin':
      return (
        <div className={'ContentLinkButton'}>
          <LinkedInIcon
            fontSize={'medium'}
            style={{color: '#1E1E1E', marginRight: '20px'}}
            onClick={() => window.open(url, '_blank').focus()}
          />
        </div>
      );
    case 'link':
      return (
        <div className={'ContentLinkButton'}>
          <LinkOutlinedIcon
            fontSize={'medium'}
            style={{color: '#1E1E1E', marginRight: '20px'}}
            onClick={() => window.open(url, '_blank').focus()}
          />
        </div>
      );
      default:
        return null;
  }
}

const makeTextLink = (entity, displayKey, onClickHandler) => {
  return (
    <u
      style={{textDecorationColor: colorMap[entity.group], cursor: 'pointer'}}
      onClick={() => onClickHandler(entity)}
    >
      {entity[displayKey]}
    </u>
  );
}

const Content = (props) => {

  const content = props.content;

  if (!content) {
    return null;
  }

  const entity = content.entity;
  const links = content.links;

  // Content placeholders
  let contentHeaderRight = null;
  let contentMain = null;

  if (entity.group === 'investor') {
    if (entity.linkedin) {
      contentHeaderRight = makeHeaderButton('linkedin', entity.linkedin);
    }
    contentMain = (
      <>
        <ContentAccordion
          header={'Deals'}
          items={links.deal}
          onClickHandler={props.getEntityContentHandler}
          displayFunc={(entity) => {
            let nameStr = entity.startup_name || 'Unknown Startup';
            if (entity.investment_stage) {
              nameStr += ` (${entity.investment_stage})`;
            }
            if (entity.deal_date) {
              nameStr += ` - ${new Date(entity.deal_date).toLocaleDateString()}`;
            }
            return nameStr
          }}
        />
      </>
    );
  } else if (entity.group === 'startup' || entity.group === 'firm') {
    if (entity.homepage) {
      contentHeaderRight = makeHeaderButton('link', entity.homepage);
    }
    if (entity.group === 'startup') {
      contentMain = (
        <>
          <p>
            <strong>Location: </strong>
            {entity.headquarters || entity.country || 'Unknown'}
          </p>
          <p>
            <strong>Industry: </strong>
            {
              entity.industry_category && entity.industry_category.name
                ? makeTextLink(entity.industry_category, 'name', props.getEntityContentHandler)
                : 'Unknown'
            }
          </p>
          <p>
            <strong>Total Funding: </strong>
            {entity.total_funding_millions ? `$${entity.total_funding_millions} M` : 'Unknown'}
          </p>
          <p>
            <strong>Valuation: </strong>
            {entity.valuation_millions ? `$${entity.valuation_millions} M` : 'Unknown'}
            {entity.valuation_updated ? ` (updated ${entity.valuation_updated})` : ''}
          </p>
          <div style={{marginBottom: '5px'}}>
            <ContentAccordion
              header={'Funding Rounds'}
              displayFunc={(entity) => {
                let nameStr = entity.investment_stage || 'Unknown';
                if (entity.deal_date) {
                  nameStr += ` (${new Date(entity.deal_date).toLocaleDateString()})`;
                }
                return nameStr
              }}
              items={links.deal}
              onClickHandler={props.getEntityContentHandler}
            />
          </div>
          <div style={{marginBottom: '5px'}}>
            <ContentAccordion
              header={'Institutional Investors'}
              displayFunc={(entity) => entity.name}
              items={entity.institional_investors}
              onClickHandler={props.getEntityContentHandler}
            />
          </div>
          <ContentAccordion
            header={'Individual Investors'}
            displayFunc={(entity) => entity.name}
            items={entity.individual_investors}
            onClickHandler={props.getEntityContentHandler}
          />
        </>
      );
    } else {
      contentMain = (
        <>
          <p>
            <strong>Location: </strong>
            {entity.headquarters || entity.country || 'Unknown'}
          </p>
          <div style={{marginBottom: '5px'}}>
            <ContentAccordion
              header={'Deals'}
              displayFunc={(entity) => {
                let nameStr = entity.startup_name || 'Unknown Startup';
                if (entity.investment_stage) {
                  nameStr += ` (${entity.investment_stage})`;
                }
                if (entity.deal_date) {
                  nameStr += ` - ${new Date(entity.deal_date).toLocaleDateString()}`;
                }
                return nameStr
              }}
              items={links.deal}
              onClickHandler={props.getEntityContentHandler}
            />
          </div>
        </>
      );
    }
  } else if (entity.group === 'industry') {
    contentMain = (
      <>
        <ContentAccordion
          header={'Startups'}
          items={links.startup}
          onClickHandler={props.getEntityContentHandler}
          displayFunc={(entity) => entity.name}
        />
      </>
    );
  } else if (entity.group === 'deal') {
    contentMain = (
      <>
        <p>
          <strong>Startup: </strong>
          {
            makeTextLink(links.startup, 'name', props.getEntityContentHandler)
          }
        </p>
        <p>
          <strong>Deal Stage: </strong>
          {entity.investment_stage || 'Unknown'}
        </p>
        <p>
          <strong>Deal Date: </strong>
          {entity.deal_date ? new Date(entity.deal_date).toLocaleDateString() : 'Unknown'}
        </p>
        <p>
          <strong>Deal Size: </strong>
          {entity.deal_size_millions ? `$${entity.deal_size_millions} M` : 'Unknown'}
        </p>
        <p>
          <strong>Post-Money Valuation: </strong>
          {entity.post_money_valuation_millions ? `$${entity.post_money_valuation_millions} M` : 'Unknown'}
        </p>
        <div style={{marginBottom: '5px'}}>
          <ContentAccordion
            header={'Institutional Investors'}
            displayFunc={(entity) => entity.name}
            items={entity.institional_investors}
            onClickHandler={props.getEntityContentHandler}
          />
        </div>
        <ContentAccordion
          header={'Individual Investors'}
          displayFunc={(entity) => entity.name}
          items={entity.individual_investors}
          onClickHandler={props.getEntityContentHandler}
        />
      </>
    );
  }

  return (
    <div className={'Content'}>
      <div
        className={'ContentHeader'}
        // style={{borderBottom: borderColorMap[entity.group]}}
      >
        <div className={'ContentHeaderLeft'}>
          <div>
            <p>
              <strong>
                {entity.group !== 'deal' ? entity.name : links.startup.name + ' - ' + entity.investment_stage}

              </strong>
            </p>
          </div>
          <div>{chipMap[entity.group]}</div>
        </div>
        <div className={'ContentHeaderRight'}>
          {contentHeaderRight}
        </div>
      </div>
      <div className={'ContentBody'}>
        {entity.description}
        {contentMain}
      </div>
    </div>
  );
}

export default Content;
