import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import App from './App';


const instance = axios.create({baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5051'});
instance.defaults.headers['Content-Type'] = 'application/json';
instance.defaults.timeout = 60000;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default instance;
