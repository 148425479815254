import React, {Component} from 'react';
import './App.css';
import Api from './api';
import Logo from './assets/img/tnr.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Content from './components/Content/Content';
import Searchbar from './components/Searchbar/Searchbar';
import TabSwitcher from './components/TabSwitcher/TabSwitcher';
import Loader from './components/Loader/Loader';

const muiTheme = createTheme({
  typography: {
    fontFamily: [
      'Whyte',
      'sans-serif'
    ].join(',')
  }
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openTab: 'startup',
      searchbarValue: '',
      searchbarOptions: [],
      searchbarLoading: false,
      content: null,
      contentLoading: false,
    };
  }

  setSearchbarValue = (newValue) => {
    this.setState({searchbarValue: newValue});
    if (newValue.length > 2) {
      this.api.getSearchResults(newValue);
    } else {
      this.setState({searchbarOptions: []});
    }
  }

  setSearchbarOptions = (options) => {
    this.setState({
      searchbarOptions: options,
    });
  }

  setOpenTab = (newTab) => {
    this.setState({openTab: newTab});
  }

  setSearchbarLoadingState = (newValue) => {
    this.setState({searchbarLoading: newValue});
  }

  setContent = (content) => {
    this.setState({content: content});
  }

  setContentLoadingState = (newValue) => {
    this.setState({contentLoading: newValue});
  }

  api = new Api(
    this.setSearchbarOptions,
    this.setSearchbarLoadingState,
    this.setContent,
    this.setContentLoadingState,
    this.setOpenTab,
    () => {}
  );

  render() {
    return (
      <ThemeProvider theme={muiTheme}>
        <div className={'App'}>
          <div className={'Header'}>
            <img
              src={Logo}
              alt={'logo'}
              height={'50px'}
            />
            <Searchbar
              value={this.state.searchbarValue}
              options={this.state.searchbarOptions}
              onChangeHandler={(entity) => this.api.getEntityContent(entity)}
              onInputChangeHandler={this.setSearchbarValue}
              loading={this.state.searchbarLoading}
            />
            <TabSwitcher
              openTab={this.state.openTab}
              changeOpenTabHandler={this.setOpenTab}
            />
          </div>
          <div className={'Body'}>
            {
              this.state.contentLoading
                ? (
                  <div className={'ContentLoader'}>
                    <Loader/>
                  </div>
                ) : (
                  <Content
                    content={this.state.content}
                    getEntityContentHandler={(entity) => this.api.getEntityContent(entity)}
                  />
                )
            }
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
