import instance from './index';

class API {
  constructor(
    searchResultsHandler,
    searchbarLoaderHandler,
    contentHandler,
    contentLoaderHandler,
    openTabHandler,
    alertHandler,
  ) {
    this.searchResultsHandler = searchResultsHandler;
    this.searchbarLoaderHandler = searchbarLoaderHandler;
    this.contentHandler = contentHandler;
    this.contentLoaderHandler = contentLoaderHandler;
    this.openTabHandler = openTabHandler;
    this.alertHandler = alertHandler;
  }
  genericErrorMessage = 'Something went wrong';
  
  // Get search results
  getSearchResults(query) {
    this.searchbarLoaderHandler(true);
    instance.get('/search', {
      params: {
        query: query
      }
    }).then(resp => {
      const data = resp.data;
      if (!data) {
        this.alertHandler({
          message: this.genericErrorMessage,
          severity: 'error'
        });
      } else {
        this.searchResultsHandler(data);
      }
    }).catch(err => {
      this.alertHandler({
        message: this.genericErrorMessage,
        severity: 'error'
      });
      console.log(err);
    }).finally(() => {
      this.searchbarLoaderHandler(false);
    });
  }

  // Get entity content
  getEntityContent(entity) {
    this.contentLoaderHandler(true);
    this.openTabHandler(entity.group);
    instance.get(`/${entity.group}`, {
      params: {
        id: entity.id
      }
    }).then(resp => {
      const data = resp.data;
      if (!data) {
        this.alertHandler({
          message: this.genericErrorMessage,
          severity: 'error'
        });
      } else {
        this.contentHandler(data);
      }
    }).catch(err => {
      this.alertHandler({
        message: this.genericErrorMessage,
        severity: 'error'
      });
      console.log(err);
    }).finally(() => {
      this.contentLoaderHandler(false);
    });
  }
}

export default API;
