import React from 'react';
import './TabSwitcher.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TabSwitcher = (props) => {

  const tabStyle = {textTransform: 'none'};

  return (
    <div className={'TabSwitcher'}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={props.openTab || 'startup'}
          onChange={(e, newValue) => props.changeOpenTabHandler(newValue)}
        >
          <Tab label='Startups' style={tabStyle} value={'startup'} disableRipple/>
          <Tab label='Deals' style={tabStyle} value={'deal'} disableRipple/>
          <Tab label='Industries' style={tabStyle} value={'industry'} disableRipple/>
          <Tab label='Investment Firms' style={tabStyle} value={'firm'} disableRipple/>
          <Tab label='Investors' style={tabStyle} value={'investor'} disableRipple/>
        </Tabs>
      </Box>
    </div>
  );
}

export default TabSwitcher;